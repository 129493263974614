import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchServicePartner(action) {
  try {
    const responseServicePartiner = yield callApi(() =>
      getRequest("cms/service-provider")
    );
    yield put({
      type: actions.FETCH_CMS_SERVICE_PROVIDER_SUCCESS,
      payload: responseServicePartiner.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CMS_SERVICE_PROVIDER_FAILURE });
  }
}

function* addServicePartner(action) {
  try {
    const response = yield callApi(() =>
      postRequest("cms/service-provider", action.payload)
    );
    yield put({
      type: actions.ADD_CMS_SERVICE_PROVIDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_CMS_SERVICE_PROVIDER_FAILURE });
  }
}

function* updatServicePartner(action) {
  try {
    const response = yield callApi(() =>
      putRequest("cms/service-provider", action.payload)
    );
    yield put({
      type: actions.UPDATE_CMS_SERVICE_PROVIDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CMS_SERVICE_PROVIDER_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_CMS_SERVICE_PROVIDER, fetchServicePartner),
  ]);
  yield all([takeLatest(actions.ADD_CMS_SERVICE_PROVIDER, addServicePartner)]);
  yield all([
    takeLatest(actions.UPDATE_CMS_SERVICE_PROVIDER, updatServicePartner),
  ]);
}
